import { Box, Button, Grid, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import MaskedInput from 'react-text-mask';
import eventTrackingService from '../../../services/eventtracking.service';
import '../../../styles/components/tabs.scss';
import { ApiContext } from '../useLogin';

const TextMaskCustom = (props) => {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput {...other} ref={inputRef} mask={[/[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]} placeholderChar={'\u2000'} />
    );
};

TextMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

export const CheckPhone = (props) => {
    const { onComplete, queryParams } = props;
    const { showPhoneInput, setShowPhoneInput } = props.showInput;
    const { number, setNumber } = useContext(ApiContext);
    const [phoneError, setPhoneError] = useState(false);
    const [inputFocused, setInputFocused] = useState(false);


    useEffect(() => {
        if (showPhoneInput) {
            eventTrackingService.trackContentViewEvent('/prestamos-personales/ingreso-celular');
        }
    }, [showPhoneInput]);

    useEffect(() => {
        if (queryParams && queryParams.cellphone) {
            setNumber({
                ...setNumber,
                phone: queryParams.cellphone,
                clientNumber: queryParams.cellphone,
            });
            setInputFocused(true);
        } else {
            setNumber({
                ...setNumber,
                clientNumber: null,
            });
            setInputFocused(false);
        }
    }, [queryParams]);
    
        // cuit handling
        const { clientNumber } = number;

    const handleChangeNumber = (e) => {
        let phoneNumber = e.target.value.trim();
        setInputFocused(false);
        setNumber({
            ...setNumber,
            [e.target.name]: phoneNumber,
            clientNumber: phoneNumber,
        });

        setPhoneError(false);
    };

    const validateInput = () => {
        if (clientNumber.length >= 10 && !/^15/.test(clientNumber)) {
            onComplete();
            setShowPhoneInput(false);
        } else {
            setPhoneError(true);
        }
    };

    const handleChangePhoneClicked = () => {
        setShowPhoneInput(true);
    };

    return (
        <>
            {showPhoneInput && (
                <Box className="mt-2-5">
                    <Grid container direction="row" align="center">
                        <Grid item xs={12}>
                            <h4 className="formEntryLabel">
                                Ingresá tu número de celular. Te enviaremos un SMS con un código de 4 dígitos para validarlo.
                            </h4>
                        </Grid>
                        <Grid item xs={12} sm={8} lg={8}>
                            <TextField
                                error={phoneError}
                                fullWidth={true}
                                helperText={
                                    phoneError ? 'Formato de celular inválido. Ingrésalo nuevamente' : 'Código de área sin 0 + Nº.Ejemplo: 1123456789'
                                }
                                id="cell"
                                InputProps={{ inputComponent: TextMaskCustom }}
                                label="Celular"
                                name="phone"
                                onChange={handleChangeNumber}
                                value={clientNumber}
                                focused={inputFocused}
                            />
                        </Grid>

                        <Grid item xs={11} sm={4} lg={4} className="fixedBottomOnlyMobile">
                            <Button className="nextFormButton" 
                                    variant="contained" 
                                    color="primary"
                                    onClick={validateInput}
                                    disabled={!number.phone}>
                                Siguiente
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            )}
            {!showPhoneInput && (
                <Box className="mb-05 mt-05">
                    <strong className="fs-14 fw-400 color-primary"> Celular {clientNumber} - </strong>{' '}
                    <a href="#" className="link fs-14 fw-400 color-link-blue" onClick={handleChangePhoneClicked}>
                        CAMBIAR
                    </a>
                </Box>
            )}
        </>
    );
};
