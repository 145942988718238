import { Box, Button, Grid, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import MaskedInput from 'react-text-mask';
import eventTrackingService from '../../../services/eventtracking.service';
import '../../../styles/components/tabs.scss';
import { ApiContext } from '../useLogin';

const DniMaskCustom = (props) => {
    const { inputRef, ...other } = props;
    return <MaskedInput {...other} ref={inputRef} mask={[/[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]} placeholderChar={'\u2000'} />;
};

DniMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

export const CheckDni = (props) => {
    const { onComplete, queryParams } = props;
    const { optionCuitVisible, setOptionCuitVisible } = props.optionCuitVisible;
    const { showDniInput, setShowDniInput } = props.showInput;
    const [dni, setDni] = useState('');
    const { request, setRequest, setCuits } = useContext(ApiContext);
    const [dniError, setDniError] = useState(false);
    const analyticsStep = 2;
    
    useEffect(() => {
        if (showDniInput) {
            eventTrackingService.trackContentViewEvent('/prestamos-personales/ingreso-dni');
        }
    }, [showDniInput]);

    useEffect(() => {
        if (queryParams && queryParams.idNumber) {
            setDni(queryParams.idNumber);
            setShowDniInput(false);
            setCuits([{ id: queryParams.taxIdNumber }]); // onComplete cause delay ...
        }
    }, [queryParams]);

    const handleDNIChange = (e) => {
        setOptionCuitVisible(false);
        setDni(e.target.value.trim());
        setRequest({
            ...request,
            dni: e.target.value.trim(),
        });
        setDniError(false);
    };

    const validateInput = (event) => {
        event.preventDefault();
        if (dni.length >= 7 && dni.length <= 8) {
            eventTrackingService.setDniId(dni);
            eventTrackingService.trackEvent('consulta de dni', null, null, analyticsStep);
            onComplete(dni);
        } else {
            setDniError(true);
        }
    };

    const handleChangeDniClicked = () => {
        setDni('');
        setShowDniInput(true);
    };

    return (
        <>
            {showDniInput && (
                <Box className="mt-2-5">
                    <Grid container direction="row" align="center">
                        <Grid item xs={12}>
                            <h4 className="formEntryLabel">Ingresá tu DNI</h4>
                        </Grid>
                        <Grid item xs={12} sm={8} lg={8}>
                            <TextField
                                error={dniError}
                                fullWidth={true}
                                helperText={dniError ? 'Debés ingresar un DNI válido' : 'Sin puntos y espacios'}
                                InputProps={{ inputComponent: DniMaskCustom }}
                                label="DNI"
                                name={dni}
                                onChange={handleDNIChange}
                                type="tel"
                                value={dni}
                            />
                        </Grid>

                        <Grid item xs={11} sm={4} lg={4} className={optionCuitVisible ? 'none' : 'fixedBottomOnlyMobile'}>
                            <Button
                                className="nextFormButton"
                                variant="contained"
                                color="primary"
                                onClick={validateInput}
                                disabled={!dni || optionCuitVisible}>
                                Siguiente
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            )}{' '}
            {!showDniInput && (
                <Box className="mb-05 mt-05">
                    <strong className="fs-14 fw-400 color-primary"> DNI {dni} - </strong>{' '}
                    <a href="#" className="link fs-14 fw-400 color-link-blue" onClick={handleChangeDniClicked}>
                        CAMBIAR
                    </a>
                </Box>
            )}
        </>
    );
};
